import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
 /** Modified by Imane: Loader is visible until all requests have completed*/
export class LoaderService {
  private requestCount = 0; 
  isLoading = new Subject<boolean>();

  show() {
    this.requestCount++;
    if(this.requestCount === 1){
      this.isLoading.next(true);
    } 
  }

  hide() {
    this.requestCount--;
    if(this.requestCount === 0){
      this.isLoading.next(false);
    }
    
  }
}
